<template>
  <button class="back-to-top-button" :class="{'is-absolute': isAbsolute}" v-if="isBackToTopButtonVisible" @click.prevent="scrollToTop">
    <span class="os-icon icon-arrow-up"></span>
  </button>
</template>

<script setup lang="ts">
import { ref, onMounted, onUnmounted } from 'vue';

const isBackToTopButtonVisible = ref(false);

interface IBackToTopButton {
  scrollContainer: HTMLElement;
  isAbsolute?: boolean;
}

const props = defineProps<IBackToTopButton>();

const handleScroll = () => {
  if (props.scrollContainer.scrollTop > 300) isBackToTopButtonVisible.value = true;
  else isBackToTopButtonVisible.value = false;
};

const scrollToTop = () => {
  if (props.scrollContainer) {
    props.scrollContainer.scroll({
      top: 0,
      left: 0,
      behavior: 'smooth'
    });
  }
};

onMounted(() => setTimeout(() => props.scrollContainer.addEventListener('scroll', handleScroll), 100));

onUnmounted(() => { if (props.scrollContainer) props.scrollContainer.removeEventListener('scroll', handleScroll)});
</script>

<style lang='scss'>
.back-to-top-button {
  position: fixed;
  bottom: 10px;
  right: 30px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #0d395b;
  text-decoration: none;
  border-radius: 50%;
  border: none;
  transition: opacity 0.5s ease-in-out;
  z-index: 10;
  cursor: pointer;
  
  &.is-absolute {
    position: absolute;
  }

  & .os-icon {
    width: 30px;
    height: 30px;
    flex: 0 0 30px;
    background-color: #ffffff;
    transition: transform 0.2s ease-in-out;

    &:hover {
      animation: bounce 1s ease;
    }
  }

  &:active {
    transform: scale(0.95);
    box-shadow: 0 2px 5px 1px #a6aaac;
  }
  @media (max-width: 768px) {
    right: 15px;
  }
}

// Bounce animation
@keyframes bounce {

  0% {
    transform: translateY(0);
  }
  
  50% {
    transform: translateY(-5px);
  }
  
  100% {
    transform: translateY(0);
  }
}
</style>